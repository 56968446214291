import React, { useState, useEffect } from 'react';
import Item from './Item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useIsMobileView } from '../hooks/useIsMobileView';

function Selector({ items }) {
  const [selectedItem, setSelectedItem] = useState(items[0] || null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isMobileView = useIsMobileView();

  const handleLeftClick = () => {
    setSelectedIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  const handleRightClick = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  useEffect(() => {
    if (selectedIndex >= 0) {
      setSelectedItem(items[selectedIndex]);
    }
  }, [selectedIndex, items]);

  if (!Array.isArray(items)) {
    return <div>Les éléments doivent être un tableau.</div>;
  }

  return (
    <div className="max-w-[1920px] mx-auto">
      <div className={`selector ${isMobileView ? 'm-3 p-5' : 'mx-10 p-10'} rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg flex-row`} tabIndex="0">
        <h3 className="text-white text-2xl font-bold mb-4" style={{ fontSize: '2rem' }}>Nos véhicules</h3>
        <div className={`flex items-center flex-row ${isMobileView ? 'gap-2' : 'gap-4'}`}>
          <button onClick={handleLeftClick} className="text-white bg-gray-900 bg-opacity-50 backdrop-blur-lg rounded-full p-2 hover:bg-gray-800 w-12 h-12 min-w-12 min-h-12">
            <FontAwesomeIcon icon={faChevronLeft} className="w-4 h-4 min-w-4 min-h-4" />
          </button>
          <div className={`card-list ${isMobileView ? 'p-5' : 'p-10'} rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg flex-grow max-w-[1000px] mx-auto `}>
            {selectedItem && <Item alt={`Image ${selectedItem.title}`} item={selectedItem} image={selectedItem.image} isMobileView={isMobileView} link={selectedItem.link} />}
          </div>
          <button onClick={handleRightClick} className="text-white bg-gray-900 bg-opacity-50 backdrop-blur-lg rounded-full p-2 hover:bg-gray-800 w-12 h-12 min-w-12 min-h-12">
            <FontAwesomeIcon icon={faChevronRight} className="w-4 h-4 min-w-4 min-h-4" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Selector;
