import React, { useState } from 'react';
import { Image } from 'antd';
import { useIsMobileView } from '../hooks/useIsMobileView';

const Galerie = ({ images }) => {
  const isMobileView = useIsMobileView();
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className={`max-w-[1920px] mx-auto ${isMobileView ? 'mt-10' : ''}`}>
      <div className={`galerie ${isMobileView ? 'm-3 p-5' : 'm-10 my-0 p-10'} rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg`}>
        <h3 className="text-white text-2xl font-bold mb-4" style={{ fontSize: '2rem' }}>Galerie</h3>
        <div className="relative">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 overflow-hidden transition-max-height duration-500 ease-in-out flex items-center justify-center" style={{ maxHeight: '300px' }}>
            {images.map((src, index) => (
              <div key={index} className="rounded-lg overflow-hidden flex items-center justify-center">
                <Image alt={`Image ${index}`} src={src} className="rounded-lg transition-opacity duration-500 ease-in-out object-cover" />
              </div>
            ))}
          </div>
          {isExpanded && (
            <div className="absolute bottom-0 left-0 right-0 h-48 bg-gradient-to-t from-black to-transparent rounded-b-lg"></div>
          )}
          <button onClick={() => {
            const galerie = document.querySelector('.galerie .grid');

            if (galerie.style.maxHeight === '300px') {
              galerie.style.maxHeight = 'none';
              setIsExpanded(false);
            } else {
              galerie.style.maxHeight = '300px';
              setIsExpanded(true);
            }
          }} className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-gray-900 bg-opacity-50 text-white p-2 rounded-full mt-4 mb-4">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              {!isExpanded ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 15l-7-7-7 7"></path>
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              )}
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Galerie;
